<template>
  <div  v-if="rodape!=''">
    <footer class="ftco-footer ftco-section" :style="{background:rodape[0].itiCor1}">
      <div class="container">
        <div class="row mb-5">
          <div class="col-md-6 col-lg-3">
            <div class="ftco-footer-widget mb-5">
              <h2 class="ftco-heading-2" :style="{color:rodape[0].itiCor2}">Contatos</h2>
              <div class="block-23 mb-3">
                <ul>
                  <li><span class="icon icon-map-marker"></span><span :style="{color:rodape[0].itiCor2}">{{rodape[0].itiNome}}</span></li>
                  <li><span class="icon icon-phone"></span><span class="text" :style="{color:rodape[0].itiCor2}">{{rodape[0].itiReferencia}}</span></li>
                  <li><a :href="rodape[0].itiCampoExtra2" target="_blank"><span class="icon icon-whatsapp"></span><span class="text" :style="{color:rodape[0].itiCor2}">{{rodape[0].itiCampoExtra1}}</span></a></li>
                  <li><span class="icon icon-envelope"></span><span class="text" style="font-size: 12px;" :style="{color:rodape[0].itiCor2}"> {{rodape[0].itiCampoExtra3}}</span></li>
                </ul>
              </div>
              <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-3" v-if="redes!=''">
                <li class="" v-if="redes[0].itiCampoExtra1!=''"><a :href="redes[0].itiCampoExtra1"><span class="icon-linkedin"></span></a></li>
                <li class="" v-if="redes[0].itiNome!=''"><a :href="redes[0].itiNome"><span class="icon-facebook"></span></a></li>
                <li class="" v-if="redes[0].itiReferencia!=''"><a :href="redes[0].itiReferencia"><span class="icon-instagram"></span></a></li>
                <li class="" v-if="redes[0].itiCampoExtra2!=''"><a :href="redes[0].itiCampoExtra2" target="_blank"><span class="icon-whatsapp"></span></a></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-lg-3" v-if="Segmentos!=''">
            <div class="ftco-footer-widget mb-5 ml-md-4">
              <h2 class="ftco-heading-2" :style="{color:rodape[0].itiCor2}">Institucional</h2>
              <ul class="list-unstyled">
                <li><router-link to="/" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Home</router-link></li>
                <li><router-link to="/sobre" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Sobre</router-link></li>
                <li><router-link to="/manutencao" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Manutenção</router-link></li>
                <li v-for="menu in Segmentos" :key="menu.sgiId"><router-link :to="'/modernizacao/' + menu.sgiId + '/' + formataLink(menu.sgiNomeSegmento)" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>{{menu.sgiNomeSegmento}}</router-link></li>
                <!--li><router-link to="/produtos" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Produtos</router-link></li-->
                <li><router-link to="/servicos" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Serviços</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 col-lg-3" v-if="blog!=''">
            <div class="ftco-footer-widget mb-5">
              <h2 class="ftco-heading-2" :style="{color:rodape[0].itiCor2}">Últimas Notícias</h2>
              <div class="block-21 mb-4 d-flex" v-for="bloghome in blog.slice(0,2)" :key="bloghome.itiId">
                <router-link :to="'/blogdetalhe/' + bloghome.itiId + '/' +  formataLink(bloghome.itiNome)" class="blog-img mr-4" :style="{backgroundImage: 'url(' + $imgURL + bloghome.insInstitucionalImagens[0].itgUrlImagem + ')',}"></router-link>
                <div class="text">
                  <h3 class="heading"><router-link :to="'/blogdetalhe/' + bloghome.itiId + '/' +  formataLink(bloghome.itiNome)" :style="{color:rodape[0].itiCor2}">{{bloghome.itiNome}}</router-link></h3>
                  <div class="meta">
                    <div><router-link :to="'/blogdetalhe/' + bloghome.itiId + '/' +  formataLink(bloghome.itiNome)" :style="{color:rodape[0].itiCor2}"><span class="icon-calendar"></span> {{ formattedDate(bloghome.itiData1) }}</router-link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="ftco-footer-widget mb-5">
              <h2 class="ftco-heading-2" :style="{color:rodape[0].itiCor2}">Contatos</h2>
              <ul class="list-unstyled">
                <li><router-link to="/orcamento" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Orçamento</router-link></li>
                <li><router-link to="/contato" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Contato</router-link></li>
                <li><router-link to="/trabalheconosco" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>Trabalhe Conosco</router-link></li>
                <li v-for="menu2 in Informacoes" :key="menu2.itiId"><router-link :to="'/informacoes/' + menu2.itiId + '/' + formataLink(menu2.itiNome)" :style="{color:rodape[0].itiCor2}"><span class="ion-ios-arrow-round-forward mr-2"></span>{{ menu2.itiNome }}</router-link></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center" style="display: inline-flex;justify-content: center;">
            <router-link to="/">
              <img v-if="rodape[0].insInstitucionalImagens!=''" :src="$imgURL + rodape[0].insInstitucionalImagens[0].itgUrlImagem" alt="" style="width: 100%;">
            </router-link>
          </div>
        </div>
        <div class="row" style="padding-top:60px">
          <div class="col-md-12 text-left">
            <a href="https://avancedigital.com.br/" target="_blank"><img src="/images/avanceBranco.png" alt="" style="width: 20px;"></a>
          </div>
        </div>
      </div>
    </footer>
    <div class="whatsapp-fixo" v-if="whatsapp!=''">
    <a :href="whatsapp[0].itiNome" target="_blank">
        <img v-if="whatsapp[0].insInstitucionalImagens!=''" :src="$imgURL + whatsapp[0].insInstitucionalImagens[0].itgUrlImagem"  alt="Fale Conosco pelo WhatsApp" style="width: 70px;" />
    </a>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageFooter',
  components: {

  },
  data: () => ({
    dados: [],
    rodape:[],
    Segmentos:[],
    whatsapp:[],
    Informacoes:[],
    blog:[],
    redes:[],
  }),
  methods: {
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    }
  },
  created() {
    axios.get('/Process/InsInstitucional/institucional/36/rodape')
            .then(response => {
                this.rodape = response.data;
            })
            .catch(error => {
                console.error(error);
            });

            axios.get('/Process/InsSegmentos/GetAll')
        .then(response => {
            this.Segmentos = response.data.filter(x=> x.ctiId == 47).sort((a, b) => {
                    const valorA = parseInt(a.sgiOrdem) || 0;
                    const valorB = parseInt(b.sgiOrdem) || 0;
                    return valorA - valorB;
                });
        })
        .catch(error => {
            console.error(error);
        });


        axios.get('/Process/InsInstitucional/institucional/38/whatsappflutuante')
            .then(response => {
                this.whatsapp = response.data;
            })
            .catch(error => {
                console.error(error);
            });

      axios.get('/Process/InsInstitucional/institucional/57/informacoes')
      .then(response => {
          this.Informacoes = response.data.filter(x=> x.itiVisivel).sort((a, b) => {
                    const valorA = parseInt(a.itiOrdem) || 0;
                    const valorB = parseInt(b.itiOrdem) || 0;
                    return valorA - valorB;
                });
      })
      .catch(error => {
          console.error(error);
      });

    axios.get('/Process/InsInstitucional/institucional/53/blog')
      .then(response => {
          this.blog = response.data.filter(x=> x.itiVisivel).sort((a, b) => {
            const dataA = new Date(a.itiData1);
            const dataB = new Date(b.itiData1);

            return dataA - dataB;
          }).reverse();
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/37/redessociais')
      .then(response => {
          this.redes = response.data
      })
      .catch(error => {
          console.error(error);
      });
    
  },
}
</script>

<style scoped>.menuTamanho {
  font-size: 20px;
  text-align: center;
}</style>
