<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section v-if="Dados!=''" class="ftco-section contact-section" style="padding-top: 70px;">
      <div class="container">
				<div class="row no-gutters justify-content-center mb-5">
          <div class="col-md-10 text-center heading-section ">
            <h2 class="mb-4">{{Dados[0].itiNome}}</h2>
            <p v-html="Dados[0].itiResumo1"></p>
            <p></p>
          </div>
        </div>
        <div class="row block-9 justify-content-center mb-5">
          <div class="col-md-10 mb-md-5">
            <form class="border p-5 contact-form">
              <div class="form-group">
                <input type="text" v-model="Nome" class="form-control" placeholder="Nome">
              </div>
              <div class="form-group">
                <input type="text" v-model="Email" class="form-control" placeholder="E-mail">
              </div>
              <div class="form-group">
                <input type="text" v-model="Telefone" v-mask="'(##) #####-####'" class="form-control" placeholder="Telefone">
              </div>
              <div class="form-group">
                <input type="text" v-model="Cargo" class="form-control" placeholder="Cargo Pretendido">
              </div>
              <div class="form-group">
                <textarea name="" id="" v-model="Mensagem" cols="30" rows="7" class="form-control" placeholder="Resumo das Qualificações"></textarea>
              </div>
              <div class="form-group">
                <span><b style="font-size:10px;">envie seu Currículo: Formatos aceitos:<br />.doc, .docx e .pdf</b></span>
                      <v-file-input v-model="selectedFile" label="Anexar Arquivo (Opcional)" variant="underlined" @change="handleFileChange"
                      prepend-icon="mdi-account-circle" accept=".doc,.docx,.pdf"></v-file-input>
              </div>
              <div class="form-group">
                <button @click.prevent="EnviaEmail()" class="btn btn-primary py-3 px-5">Enviar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'TrabalheConoscoView',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    topo:[],
    Dados:[],
    Nome:'',
    Email:'',
    Telefone:'',
    Cargo:'',
    Assunto:'',
    Mensagem:'',
    _dadosEmail:{
      nomeDe: '',
      nomePara: "Advance Elevadores",
      assunto: '',
      destinatario: '',
      emailResposta: '',
      textoEmail: '',
      nomeAnexo: '',
      anexo: '',
      tipoAnexo: ''
    },
    selectedFile: null,
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Trabalhe Conosco - Advance Elevadores',
        meta: [
          { name: "description", content: "Faça parte da Equipe Advance Elevadores" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    handleFileChange() {
      if (this.selectedFile && this.selectedFile[0]) { // Verifique se há um arquivo no array
        const file = this.selectedFile[0]; // Obtenha o primeiro arquivo do array
        this._dadosEmail.nomeAnexo = file.name;

        const reader = new FileReader();
        reader.onload = () => {
          this._dadosEmail.anexo = reader.result.split(",")[1]; // Conteúdo do arquivo em base64
          this._dadosEmail.tipoAnexo = file.type;

          // Você pode optar por enviar o email aqui ou fazer qualquer outra ação necessária
        };
        reader.readAsDataURL(file);
      }
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        this._dadosEmail.nomeDe = this.Nome;
        this._dadosEmail.nomePara = "Advance Elevadores";
        this._dadosEmail.assunto = 'Trabalhe Conosco ' + this.Nome;
        this._dadosEmail.destinatario = this.Dados[0].itiReferencia;
        this._dadosEmail.emailResposta = this.Email;
        this._dadosEmail.textoEmail = "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Cargo Pretendido: " + this.Cargo + "<br><br>Mensagem: " + this.Mensagem;

        axios.post(`/Process/AdmControleSmtp/Enviamail`, this._dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Cargo = "";
              this.Mensagem = "";
              this.selectedFile = null;
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/58/topotrabalhe')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/59/dadostrabalhe')
      .then(response => {
          this.Dados = response.data
      })
      .catch(error => {
          console.error(error);
      });
  },
}
</script>

<style></style>
