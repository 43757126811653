<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
		<section class="ftco-section">
			<div class="container">
				<div class="row">
          <div style="text-align:center;">
              <router-link to="/blog" style="float:right;">Voltar</router-link>
            </div>
          <div class="col-lg-12" v-if="detalhe!=''">
            <h2 class="mb-3">{{detalhe.itiNome}}</h2>
            <p style="text-align: justify;padding-top: 20px;" v-html="detalhe.itiDescricao1"></p>
          </div> <!-- .col-md-8 -->
          <div class="" v-if="detalhe!='' && detalhe.insInstitucionalImagens!=''">
            <div class="" style="padding: 10px;">
              <div v-for="(imag,index) in detalhe.insInstitucionalImagens.sort((a, b) => {
                    const valorA = parseInt(a.itgOrdem) || 0;
                    const valorB = parseInt(b.itgOrdem) || 0;
                    return valorA - valorB;})" :key="imag.itgId" class="row" style="padding-top: 40px;">
                  <div class="col-lg-4" v-if="index % 2">
                      <img :src="$imgURL + imag.itgUrlImagem" alt="" style="width: 375px;">
                  </div>
                  <div class="col-lg-8" style="padding-left: 20px;" v-else>
                    <p v-html="imag.itgResumo" style="text-align: justify;"></p>
                  </div>
                  <div class="col-lg-8" v-if="index % 2">
                      <p v-html="imag.itgResumo" style="text-align: justify;"></p>
                  </div>
                  <div v-else>
                      <div class="col-lg-4">
                      <img :src="$imgURL + imag.itgUrlImagem" alt="" style="width: 345px;">
                  </div>
                  </div>
               </div>
              </div>
          </div>
        </div>
			</div>
		</section>
    <CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: '/BlogDetalheView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  setup() {
        const route = useRoute();
        const id = route.params.id;
        const nomeProduto = route.params.nome;
        return {
            id
        };
    },
  data: () => ({
    topo:[],
    detalhe:[],
  }),
  methods: {
    carregaMetas(titlenews,value) {
			useHead({
				title: titlenews + ' - Blog Detalhe - Advance Elevadores',
        meta: [
          { name: "description", content: value },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    removerTagsHTML(html) {
      const element = document.createElement('div');
      element.innerHTML = html;
      return element.textContent || element.innerText || '';
  },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    

    axios.get('/Process/InsInstitucional/institucional/52/topoblog')
      .then(response => {
          this.topo = response.data
          console.log('topo')
          console.log(this.topo)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/GetById/' + this.id)
      .then(response => {
          this.detalhe = response.data
          this.carregaMetas(this.detalhe.itiNome, this.removerTagsHTML(this.detalhe.itiResumo1));
          console.log('detalhe')
          console.log(this.detalhe)
      })
      .catch(error => {
          console.error(error);
      });
  },
}
</script>

<style></style>
