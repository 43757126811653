<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section">
			<div class="container">
				<div class="row">
          <div class="col-lg-3 col-12">
            <h4>Categorias</h4>
            <ul class="list-group">
              <li class="list-group-item" v-for="seg in Segmentos" :key="seg.sgiId">
                 <a href="#" @click.prevent="CarregaProdutos(seg.sgiId,seg.sgiNomeSegmento )">{{seg.sgiNomeSegmento}}</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-9 col-12" v-if="produtos!=''">
            <div class="row">
              <div class="col-lg-12">
                <h4 style="padding-top: 40px;">{{NomeCategoria}}</h4>
              </div>
               <div class="col-md-6 col-lg-4" v-for="prod in produtos" :key="prod.itiId">
                  <div class="staff border">
                    <div class="img-wrap d-flex align-items-stretch">
                      <div v-if="prod.insInstitucionalImagens!=''" class="img align-self-stretch" :style="{backgroundImage: `url('${$imgURL + prod.insInstitucionalImagens[0].itgUrlImagem}')`,backgroundPosition: 'contain'}"></div>
                    </div>
                    <div class="text pt-3 px-3 pb-4 text-center">
                      <h3>{{prod.itiNome}}</h3>
                      <span class="position mb-2">Referência: {{prod.itiReferencia}}</span>
                      <div class="faded">
                        <p>{{prod.itiCampoExtra1}}</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>		
				</div>
			</div>
		</section>

    <CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'ProdutosView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  data: () => ({
    topo:[],
    Segmentos:[],
    produtosGeral:[],
    produtos:[],
    NomeCategoria:'',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Produtos - Advance Elevadores',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    CarregaProdutos(sgiId, nome){
       this.produtos = this.produtosGeral.filter(x=> x.insInstitucionalSegmentos.some(w=> w.insSegmentos.sgiId == sgiId));
       this.NomeCategoria = nome;
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

  axios.get('/Process/InsInstitucional/institucional/48/topoprodutoso')
    .then(response => {
        this.topo = response.data
    })
    .catch(error => {
        console.error(error);
    });

     await axios.get('/Process/InsInstitucional/institucional/49/produtos')
      .then(response => {
          this.produtosGeral = response.data.sort((a, b) => {
                    const valorA = parseInt(a.itiOrdem) || 0;
                    const valorB = parseInt(b.itiOrdem) || 0;
                    return valorA - valorB;
                });
      })
      .catch(error => {
          console.error(error);
      });

      await axios.get('/Process/InsSegmentos/GetAll')
        .then(response => {
            this.Segmentos = response.data.filter(x=> x.ctiId == 49).sort((a, b) => {
                    const valorA = parseInt(a.sgiOrdem) || 0;
                    const valorB = parseInt(b.sgiOrdem) || 0;
                    return valorA - valorB;
                });
            this.CarregaProdutos(this.Segmentos[0].sgiId,this.Segmentos[0].sgiNomeSegmento);
        })
        .catch(error => {
            console.error(error);
        });
  },
}
</script>

<style></style>
