<template>
  <div>
    <section class="hero-wrap hero-wrap-2" style="background-image: url('/images/bg_1.jpg');">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">Serviço Detalhe</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" id="section-counter" style="padding-top:60px;">
      <div class="container consult-wrap">
        <div class="row d-flex align-items-stretch">
          <div class="col-md-6 wrap-about align-items-stretch d-flex">
            <div class="img" style="background-image: url(/images/about.jpg);"></div>
          </div>
          <div class="col-md-6 wrap-about  py-md-5 pl-md-5">
            <div class="heading-section mb-4">
              <a type="" href="javascript:window.history.go(-1)" style="float:right;">Voltar</a>
              <h2>Título Serviço</h2>
            </div>
            <p style="text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris bibendum
              facilisis tellus eget sagittis.
              Curabitur luctus libero quis ullamcorper congue. Curabitur quis venenatis erat. Duis convallis sem odio, a
              pellentesque mauris dapibus at. Nam sem sem, bibendum et nulla a, dignissim iaculis tortor. Vivamus faucibus
              mi ac lorem finibus bibendum. Proin porttitor maximus erat, posuere pellentesque tortor venenatis in.
              Vestibulum nisi erat, eleifend et lorem id, rhoncus malesuada ipsum.
            </p>
            <p style="text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris bibendum
              facilisis tellus eget sagittis.
              Curabitur luctus libero quis ullamcorper congue. Curabitur quis venenatis erat. Duis convallis sem odio, a
              pellentesque mauris dapibus at. Nam sem sem, bibendum et nulla a, dignissim iaculis tortor. Vivamus faucibus
              mi ac lorem finibus bibendum. Proin porttitor maximus erat, posuere pellentesque tortor venenatis in.
              Vestibulum nisi erat, eleifend et lorem id, rhoncus malesuada ipsum.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-intro ftco-no-pb img" style="background-image: url(/images/bg_1.jpg);">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-8 d-flex align-items-center heading-section heading-section-white ">
            <h2 class="mb-3 mb-md-0">Vamos conversar sobre seu projeto?</h2>
          </div>
          <div class="col-lg-3 col-md-4 ">
            <p class="mb-0"><router-link to="/contato" class="btn btn-secondary py-3 px-4">Solicitar
                Contato</router-link></p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
export default {
  name: 'ServicosDetalheView',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    missao: true,
    visao: false,
    valores: false,
    video: false,
  }),
  methods: {
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    console.log('_idioma')
    console.log(_idioma)
  },
}
</script>

<style></style>
