<template>
  <div>
    <!-- Aqui vai menu computador-->

    <div class="hidden-sm hidden-xs">
      <div class="bg-top navbar-light d-flex flex-column-reverse" v-if="cabecalho!= ''">
    	<div class="container">
    		<div class="row no-gutters d-flex align-items-center align-items-stretch">
    			<div class="col-md-4 d-flex align-items-center py-4">
    				<router-link class="navbar-brand" to="/">
              <img v-if="cabecalho[0].insInstitucionalImagens!=''" :src="$imgURL + cabecalho[0].insInstitucionalImagens[0].itgUrlImagem" /> 
            </router-link>
    			</div>
	    		<div class="col-lg-8 d-block">
		    		<div class="row d-flex" style="padding-top: 20px;">
					    <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
					    	<div class="icon d-flex justify-content-center align-items-center"><span class="ion-ios-paper-plane"></span></div>
					    	<div class="text">
					    		<span>E-mail</span>
						    	<span>{{cabecalho[0].itiNome}}</span>
						    </div>
					    </div>
					    <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
					    	<div class="icon d-flex justify-content-center align-items-center"><span class="ion-ios-call"></span></div>
						    <div class="text">
						    	<span>Telefone</span>
						    	<span><a :href="cabecalho[0].itiCampoExtra2" target="_blank">{{cabecalho[0].itiReferencia}}<img src="/images/whattsico.png" style="width: 20px;" /></a></span>
						    </div>
					    </div>
					    <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
					    	<div class="icon d-flex justify-content-center align-items-center"><span class="ion-ios-time"></span></div>
						    <div class="text">
						    	<span>Plantão 24 horas</span>
						    	<span style="display: inline-flex;">{{cabecalho[0].itiCampoExtra1}}</span>
						    </div>
					    </div>
				    </div>
			    </div>
		    </div>
		  </div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-dark ftco-navbar-light" id="ftco-navbar">
	    <div class="container d-flex align-items-center">
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
	        <span class="oi oi-menu"></span> Menu
	      </button>
	      <div class="collapse navbar-collapse" id="ftco-nav">
	        <ul class="navbar-nav mr-auto">
	        	<li class="nav-item"><router-link to="/" class="nav-link" @click="showModernizacaoMenu=false">Home</router-link></li>
	        	<li class="nav-item"><router-link to="/sobre" class="nav-link" @click="showModernizacaoMenu=false">Sobre</router-link></li>
	        	<li class="nav-item"><router-link to="/manutencao" class="nav-link" @click="showModernizacaoMenu=false">Manutenção</router-link></li>
            <li class="nav-item position-relative" v-if="Segmentos!=''">
              <a class="nav-link dropdown-toggle" href="#" @click.prevent="toggleModernizacaoMenu">Modernização</a>
              <div class="dropdown-menu" :class="{'displaymenu': showModernizacaoMenu}" aria-labelledby="modernizacaoDropdown">
                <router-link v-for="menu in Segmentos" :key="menu.sgiId" :to="'/modernizacao/' + menu.sgiId + '/' + formataLink(menu.sgiNomeSegmento)" class="dropdown-item" @click="toggleModernizacaoMenu">{{menu.sgiNomeSegmento}}</router-link>
              </div>
            </li>
	        	<!--li class="nav-item"><router-link to="/produtos" class="nav-link" @click="showModernizacaoMenu=false">Produtos</router-link></li-->
	        	<li class="nav-item"><router-link to="/servicos" class="nav-link" @click="showModernizacaoMenu=false">Serviços</router-link></li>
	          <li class="nav-item"><router-link to="/blog" class="nav-link" @click="showModernizacaoMenu=false">Blog</router-link></li>
            <li class="nav-item"><router-link to="/contato" class="nav-link" @click="showModernizacaoMenu=false">Contato</router-link></li>
            <li class="nav-item"><router-link to="/orcamento" class="nav-link" @click="showModernizacaoMenu=false">Orçamento</router-link></li>
	        </ul>
	      </div>
	    </div>
	  </nav>
    <!-- END nav -->

    </div>

    <!-- Aqui vai menu para celular-->
    <div class="hidden-md hidden-lg" v-if="cabecalho!= ''">
      <div class="container" style="padding-bottom: 15px;">
        <div class="row">
          <div class="col-4" style="padding-top: 15px;">
            <v-menu width="300" style="padding-left: 20px;" v-if="Segmentos!=''">
              <template v-slot:activator="{ props }">
                <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <router-link to="/">
                    <v-list-item-title class="menuTamanho">Home</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/sobre">
                    <v-list-item-title class="menuTamanho">Sobre</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/manutencao">
                    <v-list-item-title class="menuTamanho">Manutenção</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item v-for="menu in Segmentos" :key="menu.sgiId">
                  <router-link :to="'/modernizacao/' + menu.sgiId + '/' + formataLink(menu.sgiNomeSegmento)">
                    <v-list-item-title class="menuTamanho">{{menu.sgiNomeSegmento}}</v-list-item-title>
                  </router-link>
                </v-list-item>
                <!--v-list-item>
                  <router-link to="/produtos">
                    <v-list-item-title class="menuTamanho">Produtos</v-list-item-title>
                  </router-link>
                </v-list-item-->
                <v-list-item>
                  <router-link to="/servicos">
                    <v-list-item-title class="menuTamanho">Serviços</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/blog">
                    <v-list-item-title class="menuTamanho">Blog</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/contato">
                    <v-list-item-title class="menuTamanho">Contato</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/orcamento">
                    <v-list-item-title class="menuTamanho">Orçamento</v-list-item-title>
                  </router-link>
                </v-list-item>
                <v-list-item>
                  <router-link to="/trabalheconosco">
                    <v-list-item-title class="menuTamanho">Trabalhe Conosco</v-list-item-title>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="col-8" style="display:flex; align-items: end; justify-content: end;">
            <router-link to="/" class="navbar-brand">
              <img  v-if="cabecalho[0].insInstitucionalImagens!=''" :src="$imgURL + cabecalho[0].insInstitucionalImagens[0].itgUrlImagem" style="width: 250px;padding-top: 15px;" /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageHeader',
  components: {

  },
  data: () => ({
    Idioma: 0,
    topo: [],
    showModernizacaoMenu: false,
    cabecalho:[],
    Segmentos:[],
  }),
  methods: {
    setaIdioma(value){
      if (localStorage.getItem('idiomaGlobus')) {
         localStorage.removeItem('idiomaGlobus');
         localStorage.setItem('idiomaGlobus', value);
      } else {
         localStorage.setItem('idiomaGlobus', value);
      }
    },
    ValidaIdioma(){
      if (localStorage.getItem('idiomaGlobus')) {
        return localStorage.getItem('idiomaGlobus');
      }
      else{
        return 1;
      }
    },
    toggleModernizacaoMenu() {
      this.showModernizacaoMenu = !this.showModernizacaoMenu;
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    }
  },
 created() {    

  axios.get('/Process/InsInstitucional/institucional/28/cabecalho')
            .then(response => {
                this.cabecalho = response.data;
            })
            .catch(error => {
                console.error(error);
            });


      axios.get('/Process/InsSegmentos/GetAll')
        .then(response => {
            this.Segmentos = response.data.filter(x=> x.ctiId == 47).sort((a, b) => {
                    const valorA = parseInt(a.sgiOrdem) || 0;
                    const valorB = parseInt(b.sgiOrdem) || 0;
                    return valorA - valorB;
                });
        })
        .catch(error => {
            console.error(error);
        });

  }
}
</script>

<style scoped>
.menuTamanho {
  font-size: 20px;
  text-align: center;
}

#modernizacaoDropdown {
  position: absolute;
  top: 100%; /* Posiciona abaixo do item "Modernização" */
  left: 0;
}

/* Estilos para o submenu */
.displaymenu {
  display: block;
}



</style>
