<template>
  <div v-if="DadosGeral!=''">
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`,backgroundSize:'100%'}">
      <div class="overlay" style="opacity: .4 !important;"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{DadosGeral[0].insInstitucionalSegmentos[0].insSegmentos.sgiNomeSegmento}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section ftco-no-pt ftco-no-pb ftco-counter" style="padding-top: 60px;;">
      <div class="container consult-wrap">
        <div class="row d-flex align-items-stretch">
          <div class="col-md-6 wrap-about  py-md-5 pl-md-5">
            <div class="heading-section mb-4">
              <span class="subheading">{{DadosGeral[0].insInstitucionalSegmentos[0].insSegmentos.sgiNomeSegmento}}</span>
              <h2 v-html="DadosGeral[0].insInstitucionalSegmentos[0].insSegmentos.sgiResumo"></h2>
            </div>
            <p style="text-align: justify;" v-html="DadosGeral[0].insInstitucionalSegmentos[0].insSegmentos.sgiDescricao1"></p>
            <div class="row mt-5">
            </div>
          </div>
          <div class="col-md-6 wrap-about align-items-stretch d-flex">
            <div v-if="imgSeg!=''" class="img" :style="{backgroundImage: `url('${$imgURL + imgSeg}')`,backgroundPosition: 'initial'}"></div>
          </div>
        </div>
      </div>
    </section>
<section class="ftco-section ftco-no-pt ftco-no-pb ftco-counter" style="padding-top: 30px;">
	<div class="container">
		<div class="row tabulation mt-4 " style="padding-bottom: 120px;">
  				<div class="col-md-4">
						<ul class="nav nav-pills nav-fill d-md-flex d-block flex-column">
						  <li class="nav-item text-left" v-for="dado in DadosGeral" :key="dado.itiId">
						    <a href="#" class="nav-link active py-4" @click.prevent="detalheDado(dado)">{{dado.itiNome}}</a>
						  </li>
						</ul>
					</div>
					<div class="col-md-8">
						<div class="tab-content">
						  <div class="tab-pane container p-0 active" id="services-1">
						  	<h3 v-html="Titulo"></h3>
						  	<p style="text-align: justify;" v-html="Descricao"></p>
						  </div>
                <div class="container">
                  <div class="row align-items-center d-flex">
                    <div class="row gallery-item">
                      <div class="images" v-viewer v-if="ImagensDado.length > 1">
                        <img v-for="img in ImagensDado" :key="img.itgId" :src="$imgURL + img.itgUrlImagem" style="height: 150px;padding: 10px;cursor: pointer;">
                      </div>
                      <div v-else>
                        <img v-for="img in ImagensDado" :key="img.itgId" :src="$imgURL + img.itgUrlImagem" style="padding: 10px;cursor: pointer;width: 100%;">
                      </div>
                    </div>
                  </div>
                </div>
		
						</div>
					</div>
				</div>

  </div>
</section>

<CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import CallAction from '@/components/PageCallAction.vue'
import { useRoute } from 'vue-router';
import axios from 'axios';

export default {
  name: 'ModernizacaoView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  setup() {
        const route = useRoute();
        const id = route.params.id;
        const nomeProduto = route.params.nome;
        return {
            id
        };
    },
  data: () => ({
    topo:[],
    DadosGeral:[],
    Titulo:'',
    Descricao:'',
    ImagensDado:[],
    SegmentoImagem:[],
    imgSeg:'',
  }),
  methods: {
    carregaMetas(titlenews,value) {
			useHead({
				title: titlenews + ' - Advance Elevadores',
        meta: [
          { name: "description", content: value },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    detalheDado(dado){
      this.Titulo = dado.itiNome;
      this.Descricao = dado.itiResumo1;
      this.ImagensDado = dado.insInstitucionalImagens;
    },
    removerTagsHTML(html) {
      const element = document.createElement('div');
      element.innerHTML = html;
      return element.textContent || element.innerText || '';
  },
  },
  created() {
   

    axios.get('/Process/InsInstitucional/institucional/46/topomodernizacao')
      .then(response => {
          this.topo = response.data
          console.log('topo')
          console.log(this.topo)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/47/modernizacoes')
      .then(response => {
          this.DadosGeral = response.data.filter(x=> x.insInstitucionalSegmentos.some(w=> w.insSegmentos.sgiId == this.id))
          console.log('DadosGeral')
          console.log(this.DadosGeral)
          this.carregaMetas(this.DadosGeral[0].insInstitucionalSegmentos[0].insSegmentos.sgiNomeSegmento,this.removerTagsHTML(this.DadosGeral[0].insInstitucionalSegmentos[0].insSegmentos.sgiDescricao1) );
          this.detalheDado(this.DadosGeral[0]);
          
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsSegmentosImagens/GetAll')
      .then(response => {
          this.SegmentoImagem = response.data.filter(x=> x.insSegmentos.sgiId == this.id);
          if (this.SegmentoImagem!=''){
            this.imgSeg = this.SegmentoImagem[0].isgUrlImagem;
          }
          console.log('SegmentoImagem')
          console.log(this.SegmentoImagem)
      })
      .catch(error => {
          console.error(error);
      });

   

      },
}
</script>

<style></style>
