<template>
    <div v-if="callaction!='' && callaction[0].insInstitucionalImagens!=''">
      <section class="ftco-intro ftco-no-pb img" :style="{backgroundImage: 'url(' + $imgURL + callaction[0].insInstitucionalImagens[0].itgUrlImagem + ')'}">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9 col-md-8 d-flex align-items-center heading-section heading-section-white ">
            <h2 class="mb-3 mb-md-0">{{callaction[0].itiNome}}</h2>
          </div>
          <div class="col-lg-3 col-md-4 ">
            <p class="mb-0"><a :href="callaction[0].itiCampoExtra1" class="btn btn-secondary py-3 px-4">{{callaction[0].itiReferencia}}</a></p>
          </div>
        </div>
      </div>
    </section>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PageCallAction',
  components: {
  },
  data: () => ({
    callaction:[],
  }),
  methods: {
  
  },
  created () {
    axios.get('/Process/InsInstitucional/institucional/35/callaction')
      .then(response => {
          this.callaction = response.data
      })
      .catch(error => {
          console.error(error);
      });
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
