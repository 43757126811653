<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section contact-section" style="padding-top: 70px;" v-if="Dados!=''">
      <div class="container">
        <div class="row d-flex mb-5 contact-info justify-content-center">
        	<div class="col-md-10">
        		<div class="row mb-5">
		          <div class="col-md-4 text-center d-flex">
		          	<div class="border w-100 p-4">
			          	<div class="icon">
			          		<span class="icon-map-o"></span>
			          	</div>
			            <span>Endereço:</span> <p v-html="Dados[0].itiResumo1"></p>
			          </div>
		          </div>
		          <div class="col-md-4 text-center d-flex">
		          	<div class="border w-100 p-4">
			          	<div class="icon">
			          		<span class="icon-tablet"></span>
			          	</div>
			           <span>Telefone:</span><p v-html="Dados[0].itiResumo2"></p>
			          </div>
		          </div>
		          <div class="col-md-4 text-center d-flex">
		          	<div class="border w-100 p-4">
			          	<div class="icon">
			          		<span class="icon-envelope-o"></span>
			          	</div>
			            <span>E-mail:</span><p v-html="Dados[0].itiDescricao1"></p>
			          </div>
		          </div>
		        </div>
          </div>
        </div>
        <div class="row block-9 justify-content-center mb-5">
          <div class="col-md-10 mb-md-5">
          	<h2 class="text-center">Entre em Contato Conosco</h2>
            <form action="#" class="border p-5 contact-form" @submit.prevent="submitForm">
              <div class="form-group">
                <input type="text" v-model="Nome" class="form-control" placeholder="Nome">
              </div>
              <div class="form-group">
                <input type="text" v-model="Email" class="form-control" placeholder="E-mail">
              </div>
              <div class="form-group">
                <input type="text" v-model="Telefone" v-mask="'(##) #####-####'" class="form-control" placeholder="Telefone">
              </div>
              <div class="form-group">
                <textarea name="" id="" v-model="Mensagem" cols="30" rows="7" class="form-control" placeholder="Mensagem"></textarea>
              </div>
              <div class="form-group">
                <button type="submit" class="btn btn-primary py-3 px-5">Enviar mensagem</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section ftco-no-pb ftco-no-pt"  v-if="Dados!=''">
    	<div class="container-fluid px-0">
    		<div class="row justify-content-center">
        	<div class="col-md-12">
        		<div>
              <iframe :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + Dados[0].itiNome "
              width="100%" height="500px" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
              tabindex="0">
      </iframe>
            </div>
        	</div>
        </div>
    	</div>
    </section>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';


export default {
  name: 'ContatoView',
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    topo:[],
    Dados:[],
    Nome:'',
  Email:'',
  Telefone:'',
  Mensagem:'',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Contato - Advance Elevadores',
        meta: [
          { name: "description", content: "Fale conosco (51) 3325-5306 ou advance@advanceelevadores.com.br, localizados na Carlos Von Koseritz, 110, São João, Porto Alegre - RS" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    submitForm() {
      // Executar validação do reCAPTCHA
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LdFJUgpAAAAAAbAkkS2yZjg7Gz_oy-L1xAUVCbH', { action: 'submit' }).then((token) => {
        console.log(token)
          if (token!= null){
          this.EnviaEmail();
         }
          
        });
      });
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Advance Elevadores",
          assunto: 'Contato do Site - ' + this.Nome,
          destinatario: this.Dados[0].itiReferencia,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/54/topocontato')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });


      axios.get('/Process/InsInstitucional/institucional/55/dadoscontato')
      .then(response => {
          this.Dados = response.data
      })
      .catch(error => {
          console.error(error);
      });
      
  },
}
</script>

<style></style>
