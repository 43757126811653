<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">Orçamento</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section contact-section" style="padding-top: 70px;" v-if="Dados!=''">
      <div class="container">
        <div class="row block-9 justify-content-center mb-5">
          <div class="col-md-10 mb-md-5">
          	<h2 class="text-center">Preencha os dados do seu orçamento que lhe retornamos em breve!</h2>
            <v-form>
              <v-container>
                    <v-row>
                       <v-col cols="12" md="4">
                           <v-text-field v-model="Nome" label="Nome" variant="outlined"></v-text-field>
                       </v-col>
                       <v-col cols="12" md="4" >
                           <v-text-field v-model="Email" label="E-mail" variant="outlined"></v-text-field>
                       </v-col>
                       <v-col cols="12" md="4" >
                           <v-text-field v-model="Telefone" v-mask="'(##) #####-####'" label="Telefone" variant="outlined"></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3" >
                        <v-select v-model="TipoPredio" label="Tipo de Prédio" :items="TipoPredios"
                            item-title="Nome" item-value="Valor" variant="outlined">
                        </v-select>
                       </v-col>
                       <v-col cols="12" md="3" >
                           <v-text-field v-model="Marca" label="Marca Elevador" variant="outlined"></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3" >
                          <v-select v-model="Idade" label="Idade Elevador" :items="Idades"
                              item-title="Nome" item-value="Valor" variant="outlined">
                          </v-select>
                       </v-col>
                       <v-col cols="12" md="3" >
                           <v-text-field v-model="Elevadores" label="Quantos Elevadores" variant="outlined"></v-text-field>
                       </v-col>
                       <v-col cols="12" md="6" >
                          <v-select v-model="Acionamento" label="Tipo de Acionamento do Elevador" :items="Acionamentos"
                              item-title="Nome" item-value="Valor" variant="outlined">
                          </v-select>
                       </v-col>
                       <v-col cols="12" md="6" >
                          <v-select v-model="Maquina" label="Seu Elevador tem casa de máquinas?" :items="Maquinas"
                              item-title="Nome" item-value="Valor" variant="outlined">
                          </v-select>
                       </v-col>
                       <v-col cols="12" md="6" >
                           <v-text-field v-model="Andares" label="Quantos Andares tem o prédio" variant="outlined"></v-text-field>
                       </v-col>
                       <v-col cols="12" md="6" >
                          <v-select v-model="Frequencia" label="Que frequência o elevador é usado?" :items="Frequencias"
                              item-title="Nome" item-value="Valor" variant="outlined">
                          </v-select>
                       </v-col>
                       <v-col cols="12" md="12" >
                             <v-textarea v-model="Mensagem" label="Observações" variant="outlined"></v-textarea>
                       </v-col>
                       <v-col cols="12" md="12" >
                        <button @click.prevent="EnviaEmail()" class="btn btn-primary py-3 px-5">Solicitar orçamento</button>
                       </v-col>
                   </v-row>
              </v-container>
            </v-form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';

export default {
  name: 'ContatoView',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    topo:[],
    Dados:[],
    Nome:'',
  Email:'',
  Telefone:'',
  Mensagem:'',
  TipoPredio:null,
  TipoPredios:[
    {
      Nome: 'Hotel',
      Valor: 'Hotel'
    },
    {
      Nome: 'Hospital',
      Valor: 'Hospital'
    },
    {
      Nome: 'Escola',
      Valor: 'Escola'
    },
    {
      Nome: 'Residencial',
      Valor: 'Residencial'
    },
    {
      Nome: 'Escritório',
      Valor: 'Escritório'
    },
    {
      Nome: 'Outro',
      Valor: 'Outro'
    },
  ],
  Idade:null,
  Idades:[
    {
      Nome: 'Menos de 5 anos',
      Valor: 'Menos de 5 anos'
    },
    {
      Nome: 'Entre 5 e 15 anos',
      Valor: 'Entre 5 e 15 anos'
    },
    {
      Nome: 'Mais de 15 anos',
      Valor: 'Mais de 15 anos'
    },
  ],
  Acionamento:null,
  Acionamentos:[
    {
      Nome: 'Com cabos de tração',
      Valor: 'Com cabos de tração'
    },
    {
      Nome: 'Hidráulico',
      Valor: 'Hidráulico'
    },
    {
      Nome: 'Outro',
      Valor: 'Outro'
    }
  ],
  Maquina:null,
  Maquinas:[
    {
      Nome: 'Com casa de máquinas',
      Valor: 'Com casa de máquinas'
    },
    {
      Nome: 'Sem casa de máquinas',
      Valor: 'Sem casa de máquinas'
    },
    {
      Nome: 'Não tenho certeza',
      Valor: 'Não tenho certeza'
    },
  ],
  Elevadores:'',
  Andares:'',
  Frequencia:null,
  Frequencias:[
    {
      Nome: 'Raramente',
      Valor: 'Raramente'
    },
    {
      Nome: 'Às vezes',
      Valor: 'Às vezes'
    },
    {
      Nome: 'Muitas vezes',
      Valor: 'Muitas vezes'
    },
    {
      Nome: 'Sempre',
      Valor: 'Sempre'
    },
  ]
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Contato - Advance Elevadores',
        meta: [
          { name: "description", content: "O melhor orçamento você encontra aqui! Preencha os dados do seu orçamento que lhe retornamos em breve!" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else if (this.Telefone == '') {
        this.$mensagemAviso("Telefone Obrigatório.")
      }
      else {
        let _mensagem = '';

        _mensagem += "Nome: " + this.Nome + "<br>";
        _mensagem += "E-mail: " + this.Email + "<br>";
        _mensagem += "Telefone: " + this.Telefone + "<br>";
        _mensagem += "Tipo do prédio: " + this.TipoPredio + "<br>";
        _mensagem += "Marca: " + this.Marca + "<br>";
        _mensagem += "Idade: " + this.Idade + "<br>";
        _mensagem += "Qtde elevadores: " + this.Elevadores + "<br>";
        _mensagem += "Tipo Acionamento: " + this.Acionamento + "<br>";
        _mensagem += "Casa de Máquinas: " + this.Maquina + "<br>";
        _mensagem += "Andares prédio: " + this.Andares + "<br>";
        _mensagem += "Frequência: " + this.Frequencia + "<br>";
        _mensagem += "Observações: " + this.Mensagem;


        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Advance Elevadores",
          assunto: 'Orçamento do Site - ' + this.Nome,
          destinatario: this.Dados[0].itiReferencia,
          emailResposta: this.Email,
          textoEmail: _mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Mensagem = "";
              this.TipoPredio = null;
              this.Idade = null;
              this.Acionamento = null;
              this.Maquina = null;
              this.Elevadores="";
              this.Andares="";
              this.Frequencia=null;
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/54/topocontato')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });


      axios.get('/Process/InsInstitucional/institucional/55/dadoscontato')
      .then(response => {
          this.Dados = response.data
      })
      .catch(error => {
          console.error(error);
      });
      
  },
}
</script>

<style></style>
