<template>
    <div>
      <section class="ftco-section testimony-section" style="padding-top: 70px;" v-if="depoimentos!=''">
      <div class="container-fluid px-md-5">
        <div class="row justify-content-center mb-5">
          <div class="col-md-8 text-center heading-section ">
            <span class="subheading">{{depoimentos[0].itiNome}}</span>
            <h2 class="mb-4">{{depoimentos[0].itiReferencia}}</h2>
            <p v-html="depoimentos[0].itiResumo1">
            </p>
          </div>
        </div>
        <div class="row  justify-content-center">
          <div class="col-md-12">
            <Swiper :navigation="false" :pagination="{ clickable: true }" :slides-per-view="3" :space-between="20"
              :breakpoints="{ 768: { slidesPerView: 3 }, 0: { slidesPerView: 1 } }" class="carousel-testimony owl-carousel">
              <SwiperSlide class="item" v-for="depo in depoimg" :key="depo.itgId">
                <div class="testimony-wrap d-flex">
                  <div class="user-img" :style="{backgroundImage: 'url(' + $imgURL + depo.itgUrlImagem + ')'}">
                  </div>
                  <div class="text pl-4">
                    <p style="text-align: justify;" v-html="depo.itgResumo"></p>
                    <p class="name">{{depo.itgNome}}</p>
                    <span class="position">{{depo.itgAlt}}</span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
    </div>
</template>

<script>
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'PageDepoimentos',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    depoimentos:[],
    depoimg: [],
  }),
  methods: {
  
  },
  created () {
    axios.get('/Process/InsInstitucional/institucional/34/depoimentos')
      .then(response => {
          this.depoimentos = response.data
          if (this.depoimentos!=''){
            this.depoimg = this.depoimentos[0].insInstitucionalImagens.filter(x=> x.itgVisivel).sort((a, b) => {
                    const valorA = parseInt(a.itgOrdem) || 0;
                    const valorB = parseInt(b.itgOrdem) || 0;
                    return valorA - valorB;
                }).sort(() => Math.random() - 0.5);
          }
      })
      .catch(error => {
          console.error(error);
      });
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
