<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section v-if="detalhe!=''" class="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" id="section-counter" style="padding-top:60px;">
      <div class="container consult-wrap">
        <div class="row d-flex align-items-stretch">
          <div class="col-md-6 wrap-about align-items-stretch d-flex">
            <div v-if="detalhe.insInstitucionalImagens!=''" class="img" :style="{backgroundImage: `url('${$imgURL + detalhe.insInstitucionalImagens[0].itgUrlImagem}')`}"></div>
          </div>
          <div class="col-md-6 wrap-about  py-md-5 pl-md-5">
            <div class="heading-section mb-4">
              <router-link to="/servicos" style="float:right;">Voltar</router-link>
              <h2>{{detalhe.itiNome}}</h2>
            </div>
            <p style="text-align: justify;" v-html="detalhe.itiResumo2">
            </p>
          </div>
        </div>
      </div>
    </section>

    <CallAction></CallAction>

  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'ServicosDetalheView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  setup() {
        const route = useRoute();
        const id = route.params.id;
        const nomeProduto = route.params.nome;
        return {
            id
        };
    },
  data: () => ({
    topo:[],
    detalhe:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Serviços Detalhe - Advance Elevadores',
        meta: [
          { name: "description", content: "" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/50/toposervicos')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/GetById/' + this.id)
      .then(response => {
          this.detalhe = response.data
      })
      .catch(error => {
          console.error(error);
      });
  },
}
</script>

<style></style>
