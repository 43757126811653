<template>
  <div>
    <v-carousel hide-delimiters class="tamCar home-slider owl-carousel" show-arrows="hover" v-if="slider!='' && slider.insInstitucionalImagens!=''">
      <v-carousel-item class="slider-item" v-for="slide in slider[0].insInstitucionalImagens.filter(x=> x.itgVisivel)" :key="slide.itgId">
        <v-sheet 
          :style="{backgroundImage: 'url(' + $imgURL + slide.itgUrlImagem + ')',backgroundSize:'100%', backgroundRepeat:'no-repeat'}">
          <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-end" data-scrollax-parent="true">
              <div class="col-md-7 mb-md-5">
              </div>
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <section class="ftco-section ftco-no-pt ftco-no-pb ftco-consult alttp" v-if="formhome!=''">
      <div class="container">
        <div class="row d-flex no-gutters align-items-stretch	consult-wrap">
          <div class="col-md-5 wrap-about align-items-stretch d-flex">
            <div class=" align-self-stretch px-4 w-100" style="background-color: #0C8FCF;padding-top: 15px;">
              <h2 class="heading-white mb-4">{{formhome[0].itiNome}}</h2>
              <form action="#" class="appointment-form" @submit.prevent="submitForm">
                <div class="form-group">
                  <input type="text" v-model="Nome" class="form-control" placeholder="Nome">
                </div>
                <div class="form-group">
                  <input type="text" v-model="Telefone" v-mask="'(##) #####-####'" class="form-control" placeholder="Telefone">
                </div>
                <div class="form-group">
                  <input type="text" v-model="Email" class="form-control" placeholder="E-mail">
                </div>
                <div class="form-group">
                  <textarea name="" v-model="Mensagem" id="" cols="30" rows="4" class="form-control" placeholder="Mensagem"></textarea>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn py-3 px-4" style="color: #fff;background-color: #07123C;border-color: #07123C;">Enviar Contato</button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-7 wrap-about  align-items-stretch d-flex" v-if="itensform!=''">
            <div class="bg-white p-5">
              <h2 class="mb-4">{{itensform[0].itiNome}}</h2>
              <div class="row" v-if="itensformimg">
                <div class="col-lg-6 col-6" v-for="itens in itensformimg" :key="itens.itgId" style="justify-content: center;">
                  <div class="services">
                    <div class="icon mt-2 d-flex align-items-center" style="padding-bottom: 10px;">
                      <router-link :to="itens.itgAlt">
                        <img v-if="itens.itgUrlImagem!=''" :src="$imgURL + itens.itgUrlImagem" style="width: auto;height: 70px;">
                      </router-link>
                    </div>
                    <div class="text media-body">
                      <h3><router-link :to="itens.itgAlt">{{itens.itgNome}}</router-link></h3>
                      <p v-html="itens.itgResumo"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="apresentacao!=''" class="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" style="padding-bottom: 60px;padding-top: 60px;">
      <div class="container consult-wrap">
        <div class="row d-flex align-items-stretch">
          <div class="col-md-6 wrap-about align-items-stretch d-flex">
            <img v-if="apresentacao[0].insInstitucionalImagens!=''" :src="$imgURL + apresentacao[0].insInstitucionalImagens[0].itgUrlImagem" style="width: 100%;">
          </div>
          <div class="col-md-6 wrap-about  py-md-5 pl-md-5">
            <div class="heading-section mb-4">
              <span class="subheading">{{apresentacao[0].itiNome}}</span>
              <h2>{{apresentacao[0].itiReferencia}}</h2>
            </div>
            <p style="text-align: justify;" v-html="apresentacao[0].itiResumo1"></p>
            <div class="tabulation-2 mt-4" v-if="itensmissao!=''">
              <ul class="nav nav-pills nav-fill d-md-flex d-block">
                <li class="nav-item" v-for="(itenssobre,index) in itensmissao" :key="itenssobre.itiId">
                  <a class="nav-link py-2" :class="{ active: index === itemAtivo }" href="#" @click.prevent="textoitens=itenssobre.itiResumo1;alterarItemAtivo(index)"><span
                      class="ion-ios-home mr-2"></span>{{itenssobre.itiNome}}</a>
                </li>
              </ul>
              <div class="bg-light rounded mt-2">
                <div class="container p-0">
                  <p style="text-align: justify;" v-html="textoitens"></p>
                </div>
              </div>
            </div>
            <div class="row mt-5">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="videohome!='' && videohome[0].insInstitucionalImagens!=''" class="ftco-intro ftco-no-pb img" :style="{backgroundImage: `url('${$imgURL + videohome[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center heading-section heading-section-white ">
           <a href="#" @click.prevent="video=true;"><h2 class="mb-0">{{videohome[0].itiNome}}</h2></a>
          </div>
            <div class="col-md-8 col-12" style="padding-top: 30px;">
              <iframe width="100%" height="315" :src="videohome[0].itiReferencia" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="border:1px solid #ffffff;"></iframe>
            </div>
          </div>
        </div>
    </section>
    <Depoimentos></Depoimentos>

    <CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Depoimentos from '@/components/PageDepoimentos.vue'
import CallAction from '@/components/PageCallAction.vue'
import { useHead } from '@vueuse/head';

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    Depoimentos,
    CallAction
  },
  data: () => ({
  missao:true,
  visao:false,
  valores:false,
  video:false,
  slider:[],
  formhome:[],
  Nome:'',
  Email:'',
  Telefone:'',
  Mensagem:'',
  itensform:[],
  itensformimg:[],
  apresentacao:[],
  itensmissao:[],
  textoitens:'',
  itemAtivo: 0,
  videohome:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Home - Advance Elevadores',
        meta: [
          { name: "description", content: "A Advance Elevadores mantém o compromisso de manter a originalidade do equipamento, contando com fornecedores de componentes nacionais e multinacionais, que fabricam peças e componentes originais para elevadores e escadas rolantes, com certificados de qualidade e dentro das normas NBR e ABNT." },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    submitForm() {
      // Executar validação do reCAPTCHA
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LdFJUgpAAAAAAbAkkS2yZjg7Gz_oy-L1xAUVCbH', { action: 'submit' }).then((token) => {
        console.log(token)
          if (token!= null){
          this.EnviaEmail();
         }
          
        });
      });
    },
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    alterarItemAtivo(index) {
      this.itemAtivo = index; 
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Advance Elevadores",
          assunto: 'Contato do Site Home - ' + this.Nome,
          destinatario: this.formhome[0].itiReferencia,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();

    this.carregaMetas();
    axios.get('/Process/InsInstitucional/institucional/29/slider')
      .then(response => {
          this.slider = response.data.sort((a, b) => {
                    const valorA = parseInt(a.itiOrdem) || 0;
                    const valorB = parseInt(b.itiOrdem) || 0;
                    return valorA - valorB;
                });
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/30/formulariohome')
      .then(response => {
          this.formhome = response.data
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/31/itenshomeformulario')
      .then(response => {
          this.itensform = response.data
          if (this.itensform[0].insInstitucionalImagens!=''){
            this.itensformimg = this.itensform[0].insInstitucionalImagens.filter(x=> x.itgVisivel).sort((a, b) => {
                    const valorA = parseInt(a.itgOrdem) || 0;
                    const valorB = parseInt(b.itgOrdem) || 0;
                    return valorA - valorB;
                });
          }
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/32/apresentacaohome')
      .then(response => {
          this.apresentacao = response.data
          console.log('this.apresentacao')
          console.log(this.apresentacao)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/39/itenshomesobre')
      .then(response => {
          this.itensmissao = response.data
          this.textoitens = this.itensmissao[0].itiResumo1;
      })
      .catch(error => {
          console.error(error);
      });
     
      axios.get('/Process/InsInstitucional/institucional/33/videohome')
      .then(response => {
          this.videohome = response.data
      })
      .catch(error => {
          console.error(error);
      });
      
  },
}
</script>

<style>

.alttp {
  padding-top: 100px;
}

@media (max-width:767px) {
.owl-carousel.home-slider {
    position: relative;
    height: 130px !important; 
    z-index: 0;
}
.alttp {
  padding-top: 30px;
}
}


</style>
