
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Sobre from '../views/SobreView.vue'
import Manutencao from '../views/ManutencaoView.vue'
import Modernizacao from '../views/ModernizacaoView.vue'
import Produtos from '../views/ProdutosView.vue'
import Servicos from '../views/ServicosView.vue'
import ServicoDetalhe from '../views/ServicoDetalheView.vue'
import Blog from '../views/BlogView.vue'
import BlogDetalhe from '../views/BlogDetalheView.vue'
import Contato from '../views/ContatoView.vue'
import TrabalheConosco from '../views/TrabalheConoscoView.vue'
import Informacoes from '../views/InformacoesView.vue'
import ManutencaoDetalhe from '../views/ManutencaoDetalheView.vue'
import Orcamento from '../views/OrcamentoView.vue'

const routes = [
 { path: '/', name: 'Home', component: Home },
 { path: '/Sobre', name: 'Sobre', component: Sobre },
 { path: '/Manutencao', name: 'Manutencao', component: Manutencao },
 { path: '/Modernizacao/:id/:nome', name: 'Modernizacao', component: Modernizacao },
 { path: '/Produtos', name: 'Produtos', component: Produtos },
 { path: '/Servicos', name: 'Servicos', component: Servicos },
 { path: '/ServicoDetalhe/:id:/:nome', name: 'ServicoDetalhe', component: ServicoDetalhe },
 { path: '/Blog', name: 'Blog', component: Blog },
 { path: '/BlogDetalhe/:id/:nome', name: 'BlogDetalhe', component: BlogDetalhe },
 { path: '/Contato', name: 'Contato', component: Contato },
 { path: '/TrabalheConosco', name: 'TrabalheConosco', component: TrabalheConosco },
 { path: '/Informacoes/:id/:nome', name: 'Informacoes', component: Informacoes },
 { path: '/ManutencaoDetalhe', name: 'ManutencaoDetalhe', component: ManutencaoDetalhe },
 { path: '/Orcamento', name: 'Orcamento', component: Orcamento },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
