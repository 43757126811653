<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section">
			<div class="container">
				<div class="row" v-if="blog!=''">
          <div class="col-md-6 col-lg-4 " v-for="bl in blog" :key="bl.itiId">
            <div class="blog-entry">
              <router-link v-if="bl.insInstitucionalImagens!=''" :to="'/BlogDetalhe/' + bl.itiId + '/' + formataLink(bl.itiNome)" class="block-20 d-flex align-items-end" :style="{backgroundImage: `url('${$imgURL + bl.insInstitucionalImagens[0].itgUrlImagem}')`,backgroundSize: '100%'}">
								<div class="meta-date text-center p-2">
                  <span class="mos">{{ formattedDate(bl.itiData1) }}</span>
                </div>
              </router-link>
              <div class="text p-4">
                <h3 class="heading"><router-link :to="'/BlogDetalhe/' + bl.itiId + '/' + formataLink(bl.itiNome)">{{bl.itiNome}} </router-link></h3>
                <p v-html="bl.itiResumo1" style="min-height: 135px;text-align: justify;"></p>
                <div class="d-flex align-items-center mt-4">
	                <p class="mb-0"><router-link  :to="'/BlogDetalhe/' + bl.itiId + '/' + formataLink(bl.itiNome)" class="btn btn-primary">Leia Mais <span class="ion-ios-arrow-round-forward"></span></router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
			</div>
		</section>
    <CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'BlogView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  data: () => ({
    topo:[],
    blog:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Blog - Advance Elevadores',
        meta: [
          { name: "description", content: "Advance Elevadores trazendo sempre conteúdos atualizados sobre elevadores, manutenções, evoluções técnicas e muito mais, confira!" },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/52/topoblog')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/53/blog')
      .then(response => {
          this.blog = response.data.filter(x=> x.itiVisivel).sort((a, b) => {
            const dataA = new Date(a.itiData1);
            const dataB = new Date(b.itiData1);

            return dataA - dataB;
          }).reverse();
      })
      .catch(error => {
          console.error(error);
      });

  },
}
</script>

<style></style>
