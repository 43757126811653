<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!='' " class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`,backgroundSize:'100%'}">
      <div class="overlay" style="opacity: .4 !important;"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>

    <section v-if="sobreempresa!=''" class="ftco-section ftco-no-pt ftco-no-pb ftco-counter" style="padding-top: 60px;;">
      <div class="container consult-wrap">
        <div class="row d-flex align-items-stretch">
          <div class="col-md-6 wrap-about align-items-stretch d-flex">
            <div class="img" :style="{backgroundImage: `url('${$imgURL + sobreempresa[0].insInstitucionalImagens[0].itgUrlImagem}')`}"></div>
          </div>
          <div class="col-md-6 wrap-about  py-md-5 pl-md-5">
            <div class="heading-section mb-4">
              <span class="subheading">{{sobreempresa[0].itiNome}}</span>
              <h2>{{sobreempresa[0].itiReferencia}}</h2>
            </div>
            <p style="text-align: justify;" v-html="sobreempresa[0].itiResumo1"></p>
            <div class="row mt-5">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="sobreequipe!='' && sobreequipe[0].insInstitucionalImagens!=''" class="ftco-section ftco-consult ftco-no-pt ftco-no-pb" :style="{backgroundImage: `url('${$imgURL + sobreequipe[0].insInstitucionalImagens[0].itgUrlImagem}')`,backgroundPosition:'center'}">
    	<div class="overlay"></div>
    	<div class="container">
    		<div class="row justify-content-start">
    			<div class="col-md-6 py-5 px-md-5">
    				<div class="py-md-5">
		          <div class="heading-section heading-section-white  mb-5">
		            <h2 class="mb-4">{{sobreequipe[0].itiNome}}</h2>
		            <p style="text-align: justify;" v-html="sobreequipe[0].itiResumo1"></p>
		          </div>
		          <form action="#" class="appointment-form ">
		    				<div class="d-md-flex">
			    				<div class="form-group">
			    					<input type="text" v-model="Nome" class="form-control" placeholder="Nome">
			    				</div>
			    				<div class="form-group ml-md-4">
			    					<input type="text" v-model="Email" class="form-control" placeholder="E-mail">
			    				</div>
		    				</div>
		    				<div class="d-md-flex">
		    					<div class="form-group">
			              <textarea name="" id="" v-model="Mensagem" cols="30" rows="2" class="form-control" placeholder="Mensagem"></textarea>
			            </div>
			            <div class="form-group ml-md-4">
			              <input @click.prevent="EnviaEmail()" value="Enviar Contato" class="btn btn-white py-3 px-4">
			            </div>
		    				</div>
		    			</form>
		    		</div>
    			</div>
        </div>
    	</div>
    </section>
    <section v-if="sobreestrutura!=''" class="ftco-section ftco-no-pt ftco-no-pb ftco-counter" style="padding-top: 60px;;">
      <div class="container consult-wrap">
        <div class="row d-flex align-items-stretch">
          <div class="col-md-6 wrap-about  py-md-5 pl-md-5">
            <div class="heading-section mb-4">
              <span class="subheading">{{sobreestrutura[0].itiNome}}</span>
              <h2>{{sobreestrutura[0].itiReferencia}}</h2>
            </div>
            <p style="text-align: justify;" v-html="sobreestrutura[0].itiResumo1"></p>
            <div class="row mt-5">
            </div>
          </div>
          <div class="col-md-6 wrap-about align-items-stretch d-flex">
            <div v-if="sobreestrutura[0].insInstitucionalImagens!=''" class="img" :style="{backgroundImage: `url('${$imgURL + sobreestrutura[0].insInstitucionalImagens[0].itgUrlImagem}')`,backgroundPosition:'initial'}"></div>
          </div>
        </div>
      </div>
    </section>

    <Depoimentos></Depoimentos>

    <CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';
import Depoimentos from '@/components/PageDepoimentos.vue'
import CallAction from '@/components/PageCallAction.vue'

export default {
  name: 'SobreView',
  components: {
    Swiper,
    SwiperSlide,
    Depoimentos,
    CallAction
  },
  data: () => ({
    topo:[],
    sobreempresa:[],
    sobreequipe:[],
    Nome:'',
    Email:'',
    Mensagem:'',
    sobreestrutura:[],
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Sobre - Advance Elevadores',
        meta: [
          { name: "description", content: "A Advance Elevadores tem como proposta sempre satisfazer os clientes, garantindo a qualidade dos serviços e dos produtos que a empresa oferece." },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    formattedDate(value) {
      return this.$moment(value).format('DD/MM/YYYY')
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Advance Elevadores",
          assunto: 'Contato do Site Sobre - ' + this.Nome,
          destinatario: this.sobreequipe[0].itiReferencia,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Mensagem = "";
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();

    this.carregaMetas();

    await axios.get('/Process/InsInstitucional/institucional/40/toposobre')
      .then(response => {
          this.topo = response.data
          console.log('topo')
          console.log(this.topo)
      })
      .catch(error => {
          console.error(error);
      });


      axios.get('/Process/InsInstitucional/institucional/41/sobreempresa')
      .then(response => {
          this.sobreempresa = response.data
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/42/sobreequipe')
      .then(response => {
          this.sobreequipe = response.data
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/43/sobreestrutura')
      .then(response => {
          this.sobreestrutura = response.data
      })
      .catch(error => {
          console.error(error);
      });

  },
}
</script>
<style>
</style>
