<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section v-if="topo!=''" class="ftco-section ftco-no-pb">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-8 text-center heading-section ">
              <span class="subheading">{{topo[0].itiReferencia}}</span>
              <h2 class="mb-4">{{topo[0].itiCampoExtra1}}</h2>
              <p v-html="topo[0].itiResumo1"></p>
          </div>
        </div>
      </div>
    </section>

    <section v-for="(manu,index) in manutencoes" :key="manu.itiId" class="ftco-section" :class="{'default-color': index % 2 === 0, 'alt-color': index % 2 !== 0}">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center heading-section ">
            <span class="subheading">{{manu.itiNome}}</span>
            <h2 class="mb-4">{{manu.itiReferencia}}</h2>
            <p v-html="manu.itiResumo1"></p>
          </div>
          <div class="col-lg-4 d-flex" v-for="cara in manu.insInstitucionalImagens" :key="cara.itgId">
            <div class="services-2 border rounded text-center" @click="openDialog(cara)" style="cursor: pointer;">
              <div class="icon mt-2 d-flex justify-content-center align-items-center">
                <img v-if="cara.itgUrlImagem !=''" :src="$imgURL + cara.itgUrlImagem" style="width: 70px;padding-bottom: 5px;" />
              </div>
              <div class="text media-body">
                <h3>{{cara.itgNome}}</h3>
                <p v-html="cara.itgAlt" style="min-height: 60px;"></p>
                <p>Mais Detalhes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CallAction></CallAction>

    <v-dialog v-model="dialog" width="auto">
      <v-card title="">
        <v-card-text>
          <h2>{{selectedItem.itgNome}}</h2>
            <p v-html="selectedItem.itgResumo"></p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text="Fechar"
            @click="dialog = false"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import axios from 'axios';
import { useHead } from '@vueuse/head';
import CallAction from '@/components/PageCallAction.vue'

export default {
  name: 'ManutencaoView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  data: () => ({
    topo:[],
    manutencoes:[],
    dialog: null, 
    selectedItem: null 
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Manutenções - Advance Elevadores',
        meta: [
          { name: "description", content: "Manutenções Preventiva e Corretiva de elevadores é a realização de uma série de ações para verificar e garantir o perfeito funcionamento do equipamento, que vão desde a prevenção." },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    openDialog(cara) {
      this.selectedItem = cara; 
      this.dialog = true; 
    },
    closeDialog() {
      this.dialog = false; 
      this.selectedItem = null; 
    }
  },
  async created() {
    var _idioma = await this.ValidaIdioma();

    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/44/topomanutencao')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });


    axios.get('/Process/InsInstitucional/institucional/45/manutencoes')
      .then(response => {
          this.manutencoes = response.data
      })
      .catch(error => {
          console.error(error);
      });
  },
}
</script>

<style>
.alt-color {
  background-color: #ffffff; /* Cor alternativa, por exemplo, branco */
}

.default-color {
  background-color: #f3f3f3; /* Cor padrão, por exemplo, cinza claro */
}

</style>
