<template>
 <v-app>
    <v-main>
      <Loader v-if="$loading.value"></Loader>
          <Header />
            <router-view :key="$route.fullPath" />
            <FooterPage />
    </v-main>
  </v-app>
</template>

<script>

import Header from './components/PageHeader.vue'
import FooterPage from './components/PageFooter.vue'
import Loader from './components/PageLoader.vue'

export default {
  name: 'App',
  components: {
    Header,
    FooterPage,
    Loader
  },
  data: () => ({
  }),
  methods: {
  },
  created() {
  },
}
</script>
<style>
@import "@/assets/css/open-iconic-bootstrap.min.css";
@import "@/assets/css/animate.css";
@import "@/assets/css/magnific-popup.css";
@import "@/assets/css/aos.css";
@import "@/assets/css/ionicons.min.css";
@import "@/assets/css/flaticon.css";
@import "@/assets/css/icomoon.css";
@import "@/assets/css/style.css";
@import "@/assets/css/bootstrap.min.css";

@media (max-width:767px) {
.hero-wrap.hero-wrap-2 {
    height: 100px;
}

.hero-wrap.hero-wrap-2 .slider-text {
    height: 110px;
}

.hero-wrap.hero-wrap-2 .slider-text .bread {
    font-weight: 900;
    color: #fff;
    font-size: 18px;
}
}


@media (max-width:767px) {
  .hidden-xs {
    display: none !important;
  }

  .p-5 {
    padding: 1rem !important;
}

.wrap-about {
    margin-bottom: 0px;
}
}

@media (min-width:768px) and (max-width:991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width:1200px) {
  .hidden-lg {
    display: none !important;
  }
}

 /* WHATSAPP FIXO */
 .whatsapp-fixo {
      position: fixed;
      top: 10px;
      right: 5px;
      z-index: 999;
  }
</style>
