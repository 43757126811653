import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueViewer from 'v-viewer';
import moment from 'moment';
import axios from 'axios';
import Swal from 'sweetalert2';
import JSEncrypt from 'jsencrypt';
import { SHA256 } from 'crypto-js';
import Cookies from 'js-cookie';
import 'viewerjs/dist/viewer.css';
import 'swiper/swiper-bundle.css';
import 'moment/locale/pt-br'; 
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import { loading } from './loading';
import TheMask from 'vue-the-mask';
import { createHead } from '@vueuse/head';
import { createGtm } from '@gtm-support/vue-gtm';

SwiperCore.use([Navigation, Pagination]);

const app = createApp(App)
const head = createHead();

app.use(router)
app.use(vuetify)
app.use(VueViewer)
app.use(TheMask)
app.use(head);

app.use(
  createGtm({
    id: 'GTM-52K5XDDK', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    //nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
  }),
);

/* dados de configuração */
app.config.globalProperties.$loading = loading; 

app.config.globalProperties.$scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

app.config.globalProperties.$Cookies = Cookies;
app.config.globalProperties.$CookiesDefaults = {
  expires: 0.0832, // Expira em 2 a 5 horas 
  path: '/',
  domain: '',
  secure: false,
  sameSite: 'strict',
};

app.config.globalProperties.$moment = moment
app.config.globalProperties.$imgURL = 'https://advanceelevadores.com.br/imagensProducao/'
axios.defaults.baseURL = 'https://controle.advanceelevadores.com.br/api'
//axios.defaults.baseURL = 'http://localhost:5000/api'

// Obtém o dia do ano (1 a 366)
const dayOfYear = moment().dayOfYear();
// Obtém o ano com 4 dígitos
const year = moment().year();
// Concatena o texto com o dia do ano e o ano
const hashedCookieUsuario = SHA256(`CookieSiteAvanceAdvanceElevadores${dayOfYear}${year}`).toString();

app.config.globalProperties.$CookieUsuario = hashedCookieUsuario;

app.config.globalProperties.$mensagemSucesso = function (mensagem) {
  Swal.fire(' ', mensagem, 'success')
}
app.config.globalProperties.$mensagemErro = function (mensagem) {
  Swal.fire(' ', mensagem, 'error')
}
app.config.globalProperties.$mensagemAviso = function (mensagem) {
  Swal.fire({
    position: 'top-end',
    icon: 'warning',
    title: mensagem,
    showConfirmButton: false,
    timer: 3000 // Tempo em milissegundos (3 segundos neste exemplo)
  })
}

app.config.globalProperties.$mensagemAvisoSucesso = function (mensagem) {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: mensagem,
    showConfirmButton: false,
    timer: 2000 // Tempo em milissegundos (3 segundos neste exemplo)
  })
}

app.config.globalProperties.$mensagemConfirma = function (mensagem) {
  return Swal.fire({
    icon: 'warning',
    title: mensagem,
    showCancelButton: true,
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true
  });
};

// Crie uma instância do objeto JSEncrypt
const encryptor = new JSEncrypt();

const data = {
  server: 'mysql.advanceelevadores.com.br',
  database: 'advancedelevad',
  user: 'advancedelevad',
  password: 'ADvEle24'
};

const dataAcess = {
  ftpHost: 'ftp.advanceelevadores.com.br',
  ftpUsername: 'advanceelevadores',
  ftpPassword: 'ADvEle24',
  destinationFolderPath: '/www/ImagensProducao'
};
// Chave de criptografia
const publicKey = `-----BEGIN PUBLIC KEY-----
                      MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4cbwIH80zlW9eAEUjaIz
                      eOncsBX7JX1rHpH3Oe1kj5s8FMAJkI5Lsc5Xiy7AU48BYu3Z/MnZ+Qhpxv+zpIrx
                      JJFXmqfhmIYXStLLtYOCIC4duMfiy+KLlzrIgL/+DWsutR7D/LUDDC6ucExKDxC6
                      vYaYzmpObqm08WhvRzbbU43mgdaPzJXbhZfPVIj9w9kN+ZDYaLydKXbBvwy5okpi
                      wGkSKs0mR0HKFo4U+NpfBxth28QW2JRAY9CQmwqdoFAXsDTugoupNBZHb5GBn2MQ
                      U/JXZ+VApmZCrc+4SpdxdIL7nNTEl125pf4WnWNgPLITKQrJJNhH+AOBHVRTf555
                      cQIDAQAB
                      -----END PUBLIC KEY-----`;
// Defina as chaves na instância do JSEncrypt
encryptor.setPublicKey(publicKey);

// Criptografe os dados desejados
const dataString = JSON.stringify(data);
const dataAcessString = JSON.stringify(dataAcess);
app.config.globalProperties.$axios = axios
axios.defaults.headers.common['Authorization'] = 'Basic U2lzdGVtYUF2YW5jZUNsaTpBdlNpc0djYWQzMEx1YyE3OA==';
axios.defaults.headers.common['SisAvaKey'] = encryptor.encrypt(dataString);
axios.defaults.headers.common['SisAvaToken'] = encryptor.encrypt(dataAcessString);

axios.interceptors.request.use((config) => {
  // Configurações adicionais antes de fazer a chamada à API
  app.config.globalProperties.$loading.value = true; // Definir o estado do loader como true
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // Tratamento de sucesso da resposta da API
    app.config.globalProperties.$loading.value = false;  // Definir o estado do loader como false
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      // Tratamento de erro para status 401 (Acesso não autorizado)
      app.config.globalProperties.$mensagemErro("Acesso não autorizado.");
    } else if (error.code === "ERR_NETWORK") {
      // Tratamento de erro para erro de rede
      app.config.globalProperties.$mensagemErro("Sistema fora do ar." + error);
    } else if (error.response && error.response.data && error.response.data.message) {
      // Tratamento de erro para outras respostas com mensagem
      app.config.globalProperties.$mensagemErro(error.response.data.message);
    } else {
      // Tratamento de erro genérico
      app.config.globalProperties.$mensagemErro("Falha ao conectar, contate o administrador.");
    }
    app.config.globalProperties.$loading.value = false;
    return Promise.reject(error);
  }
);

app.mount('#app')






