<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay" style="opacity: .4 !important;"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section">
			<div class="container">
				<div class="row no-gutters justify-content-center mb-5" v-if="topo!=''">
          <div class="col-md-12 text-center heading-section ">
            <h2 class="mb-4">{{topo[0].itiReferencia}}</h2>
            <p v-html="topo[0].itiResumo1"></p>
          </div>
        </div>
        <div class="container">
             <div v-for="(imag,index) in servicosItens" :key="imag.itgId" style="padding-top: 40px;">
                <div class="row">
                        <div class="col-lg-4" v-if="index % 2">
                            <img :src="$imgURL + imag.insInstitucionalImagens[0].itgUrlImagem" alt="" style="width: 345px;">
                        </div>
                        <div class="col-lg-7" v-else>
                          <p v-html="imag.itiResumo1" style="text-align: justify;"></p>
                        </div>
                        <div class="col-lg-7" v-if="index % 2">
                            <p v-html="imag.itiResumo1" style="text-align: justify;"></p>
                        </div>
                        <div v-else>
                            <div class="col-lg-4">
                            <img :src="$imgURL + imag.insInstitucionalImagens[0].itgUrlImagem" alt="" style="width: 345px;">
                        </div>
                        </div>
                  </div>
               </div>
         </div>
        <div class="table-responsive" v-if="servicos!=''" style="display: inline-flex;padding-top: 50px;"> 
          <table>
                    <thead>
                      <tr>
                        <th style="text-align: center;font-weight: 500;width: 400px;">Modalidades</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7; width: 400px;font-size: 12px;">Manutenção Preventiva Programada</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Assistência Técnica -  Chamados Corretivos</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Chamados de Emergência 24 horas</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Chamado Corretivo</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Residencial de Baixo e Médio Fluxo</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Residencial de Médio e Alto Fluxo</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Comercial de Baixo Fluxo</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Comercial de Médio e Alto Fluxo</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Seguro de responsabilidade civil de 1,5k</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Relatório de Inspeção Anual</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Consultor Técnico Comercial</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Maior estoque de PEÇAS DE REPOSIÇÃO do RS</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Especialista dedicado por Fabricante</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Cobertura de peças</td>
                      </tr>
                      <tr>
                          <td style="text-align: left;font-weight: 500;background-color: #f7f7f7;width: 400px;font-size: 12px;">Contratar</td>
                      </tr>
                    </tbody>
                  </table>
                  <table v-for="itemb in servicos" :key="itemb.institucionalId" style="padding-left: 5px;">
                    <thead>
                      <tr>
                        <th style="text-align: center;" >{{ itemb.itiNome }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td v-if="itemb.itiReferencia == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiReferencia == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiReferencia }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra1 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra1 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra1 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra2 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra2 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra2 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra3 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra3 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra3 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra4 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra4 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra4 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra5 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra5 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra5 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra6 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra6 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra6 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra7 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra7 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra7 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra8 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra8 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra8 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra9 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra9 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra9 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiCampoExtra10 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiCampoExtra10 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiCampoExtra10 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiTextoBtn1 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiTextoBtn1 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiTextoBtn1 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiTextoBtn2 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiTextoBtn2 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiTextoBtn2 }}</td>
                      </tr>
                      <tr>
                        <td v-if="itemb.itiTextoBtn3 == 'v'" style="text-align: center;"><img src="/images/success.gif"></td>
                        <td v-else-if="itemb.itiTextoBtn3 == 'x'" style="text-align: center;"><img src="/images/fail.gif"></td>
                        <td v-else style="text-align: center;font-weight: 500;">{{ itemb.itiTextoBtn3 }}</td>
                      </tr>
                      <tr>
                          <td style="text-align: center;"><input @click="openDialog(itemb.itiNome)" value="Quero Contratar" class="btn btn-primary" style="height: 28px;font-size: 12px;"></td>
                      </tr>
                    </tbody>
                  </table>

                  <v-dialog v-model="dialog" width="auto">
                      <v-card title="">
                        <v-card-text>
                          <form action="#" class="contact-form" @submit.prevent="submitForm">
                              <div class="form-group">
                                <input type="text" v-model="Nome" class="form-control" placeholder="Nome">
                              </div>
                              <div class="form-group">
                                <input type="text" v-model="Email" class="form-control" placeholder="E-mail">
                              </div>
                              <div class="form-group">
                                <input type="text" v-model="Telefone" v-mask="'(##) #####-####'" class="form-control" placeholder="Telefone">
                              </div>
                              <div class="form-group">
                                <textarea name="" id="" v-model="Mensagem" cols="30" rows="7" class="form-control" placeholder="Mensagem"></textarea>
                              </div>
                              <div class="form-group">
                                <button type="submit" class="btn btn-primary py-3 px-5">Enviar mensagem</button>
                              </div>
                            </form>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            text="Fechar"
                            @click="dialog = false"
                          ></v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
          </div>
        </div>
		</section>
    <CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'ServicosView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  data: () => ({
    topo:[],
    servicos:[],
    servicosItens:[],
    Dados:[],
    dialog: null, 
    Nome:'',
  Email:'',
  Telefone:'',
  Mensagem:'',
  }),
  methods: {
    carregaMetas() {
			useHead({
				title: 'Serviços - Advance Elevadores',
        meta: [
          { name: "description", content: "Somos especialista em manutenção e assistência técnica de elevadores multimarcas e buscamos soluções para todos os tipos de edificação de acordo com cada necessidade." },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    openDialog(cara) {
    this.Mensagem = "Tenho interesse na modalidade: " + cara; 
      this.dialog = true; 
    },
    closeDialog() {
      this.dialog = false; 
    },
    formataLink(value){
      return value.replaceAll('/','').replaceAll(' ','-').replaceAll('.','');
    },
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    submitForm() {
      // Executar validação do reCAPTCHA
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LdFJUgpAAAAAAbAkkS2yZjg7Gz_oy-L1xAUVCbH', { action: 'submit' }).then((token) => {
        console.log(token)
          if (token!= null){
          this.EnviaEmail();
         }
          
        });
      });
    },
    EnviaEmail(){
      if (this.Nome == '') {
        this.$mensagemAviso("Nome Obrigatório.")
      }
      else if (this.Email == '') {
        this.$mensagemAviso("E-mail Obrigatório.")
      }
      else {
        let _dadosEmail = {
          nomeDe: this.Nome,
          nomePara: "Advance Elevadores",
          assunto: 'Contratar Serviço do Site - ' + this.Nome,
          destinatario: this.Dados[0].itiReferencia,
          emailResposta: this.Email,
          textoEmail: "Nome: " + this.Nome + "<br>" + "E-mail: " + this.Email + "<br>Telefone: " + this.Telefone + "<br>Mensagem: " + this.Mensagem,
          nomeAnexo: '',
          anexo: '',
          tipoAnexo: ''
        }
        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
          .then(response => {
            if (response.data >= 1) {
              this.$mensagemSucesso("E-mail enviado com sucesso.")
              this.Nome = "";
              this.Email = "";
              this.Telefone = "";
              this.Mensagem = "";
              this.dialog = false; 
            }
          });
      }
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();
    this.carregaMetas();

    axios.get('/Process/InsInstitucional/institucional/50/toposervicos')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/51/servicos')
      .then(response => {
          this.servicos = response.data
          console.log('servicos')
          console.log(this.servicos)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/60/servicositens')
      .then(response => {
          this.servicosItens = response.data
          console.log('servicosItens')
          console.log(this.servicosItens)
      })
      .catch(error => {
          console.error(error);
      });

      axios.get('/Process/InsInstitucional/institucional/55/dadoscontato')
      .then(response => {
          this.Dados = response.data
      })
      .catch(error => {
          console.error(error);
      });
  },
}
</script>

<style scoped>

table tr td{
  border: 1px solid #cccccc;
  height: 40px;
  padding: 5px;
  font-size: 13px;
}

thead tr th {
  border: 1px solid #cccccc;
  background-color: #f7f7f7;
  font-weight: 500;
  padding: 5px;
}

@media (max-width:767px) {

  table tr td{
  border: 1px solid #cccccc;
  height: 83px !important;
  padding: 5px;
  font-size: 13px;
}
}

</style>
