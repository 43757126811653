<template>
  <div>
    <section v-if="topo!='' && topo[0].insInstitucionalImagens!=''" class="hero-wrap hero-wrap-2" :style="{backgroundImage: `url('${$imgURL + topo[0].insInstitucionalImagens[0].itgUrlImagem}')`}">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">{{topo[0].itiNome}}</h1>
          </div>
        </div>
      </div>
    </section>
    <section class="ftco-section">
			<div class="container">
				<div class="row">
          <div class="col-lg-3 col-12">
            <h4>Informações Gerais</h4>
            <ul class="list-group" v-if="DadosGeral!=''">
              <li class="list-group-item" v-for="seg in DadosGeral" :key="seg.itiId">
                <a href="#" @click.prevent="CarregaInformacoes(seg.itiId )">{{seg.itiNome}}</a>
              </li>
            </ul>
          </div>
          <div class="col-lg-9 col-12" v-if="Dados!=''">
            <div class="container">
              <div class="row no-gutters slider-text align-items-center justify-content-center">
                <div class="col-md-9 text-center">
                  <h1 class="mb-2 bread" style="padding-top: 40px;">{{NomeInformacao}}</h1>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <p style="text-align: justify;" v-html="Dados[0].itiResumo1"></p>
              </div>
            </div>
          </div>		
				</div>
			</div>
		</section>

    <CallAction></CallAction>
  </div>
</template>

<script>
//import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import CallAction from '@/components/PageCallAction.vue'
import axios from 'axios';

export default {
  name: 'InformacoesView',
  components: {
    Swiper,
    SwiperSlide,
    CallAction
  },
  setup() {
        const route = useRoute();
        const id = route.params.id;
        const nomeProduto = route.params.nome;
        return {
            id
        };
    },
  data: () => ({
    topo:[],
    Dados:[],
    DadosGeral:[],
    NomeInformacao:'',
  }),
  methods: {
    carregaMetas(titlenews,value) {
			useHead({
				title: titlenews + ' - Advance Elevadores',
        meta: [
          { name: "description", content: value },
          { name: "author", content: "Avance Digital" },
          { httpEquiv: "Content-Language", content: "pt-BR" }, 
          { charset: "UTF-8" }, 
          { name: "robots", content: "index, follow" }, 
        ],
			})
		},
    ValidaIdioma() {
      if (localStorage.getItem('idioma')) {
        return localStorage.getItem('idioma');
      }
      else {
        return 1;
      }
    },
    CarregaInformacoes(itiId){
       this.Dados = this.DadosGeral.filter(x=> x.itiId == itiId);
       this.NomeInformacao = this.Dados[0].itiNome;
       this.carregaMetas(this.NomeInformacao, "Esta página apresenta informações gerais sobre a empresa e a política de privacidade deste website descrevendo os tipos de informações pessoais que podem ser coletados em nosso website e como estas serão utilizadas para melhor prestar os nossos serviços.");
    },
  },
  async created() {
    var _idioma = await this.ValidaIdioma();

    axios.get('/Process/InsInstitucional/institucional/56/topoinformacoes')
      .then(response => {
          this.topo = response.data
      })
      .catch(error => {
          console.error(error);
      });

     await axios.get('/Process/InsInstitucional/institucional/57/informacoes')
      .then(response => {
          this.DadosGeral = response.data.sort((a, b) => {
                    const valorA = parseInt(a.itiOrdem) || 0;
                    const valorB = parseInt(b.itiOrdem) || 0;
                    return valorA - valorB;
                });
          
          this.CarregaInformacoes(this.id);
          console.log('Dados')
          console.log(this.Dados)
      })
      .catch(error => {
          console.error(error);
      });
    },
}
</script>

<style></style>
